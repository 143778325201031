import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import CookieBar from './cookieBar';
import KontentLogo from "./kontentLogo";

const Footer = () => {
    const data = useStaticQuery(graphql`
    query PolicyPrivacy {
      kontentTypePrivacypolicy {
        contentItems {
          elements {
            policy_text {
              value
            }
          }
        }
      }
      kontentItemFooter {
        elements {
          details {
            value
          }
          email_address {
            value
          }
          email_description {
            value
          }
          phone_description {
            value
          }
          phone_number {
            value
          }
        }
      }
    }`);
    var privacyPolicy = data.kontentTypePrivacypolicy?.contentItems[0];
    var footerItem = data.kontentItemFooter?.elements;
    return (
    <footer className="au-footer">
        <div className="grid-container">
            <div className="au-footer__main">
                <div className="au-footer__main-img">
                    <a href="/"><KontentLogo></KontentLogo></a>
                    <p><button data-open="policy-modal">Polityka prywatności</button></p>
                </div>
                <div className="au-footer__main-text">
                  <div dangerouslySetInnerHTML={{__html:footerItem?.details?.value}} />
                  <a href={footerItem?.phone_description.value}>{footerItem?.phone_number.value}</a>
                  <span>|</span>
                  <a href={footerItem?.email_description.value}>{footerItem?.email_address.value}</a>
                  <div className="large reveal policy-reveal" id="policy-modal" data-reveal="" >
                      <div dangerouslySetInnerHTML={{__html: privacyPolicy?.elements.policy_text.value}}></div>
                      <button className="close-button" data-close="" aria-label="Close modal" type="button">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                </div>
            </div>
        </div>
        <CookieBar />
    </footer>
    )
}

export default Footer
