import React, {useState} from 'react'

const MobileNavigation = () => {

    const [active, setActive] = useState(false);

    const toggleMenu = () => {
        setActive(!active);
    }

    let container = (
    <div className="au-header__menu-mobile hide-for-large">
        <button className="menu-icon" type="button" onClick={toggleMenu}></button>
        <div className={`au-header__menu-mobile-container ${active ? 'active' : ''}`}>
            <ul>
                <li><a href="#test">Testy</a></li>
                <li><a href="#training">Szkolenie</a></li>
                <li><a href="#about">O nas</a></li>
                <li><a href="#contact">Kontakt</a></li>
            </ul>
            <button className="close-button" aria-label="Close modal" type="button" onClick={toggleMenu}>
                <span aria-hidden="true"><strong>&times;</strong></span>
            </button>
        </div>
    </div>
    )

    return (
        <>
            {container}
        </>
    )
}

export default MobileNavigation;