import React from "react"
import Navigation from './navigation';
import MobileNavigation from './mobileNavigation';
import KontentLogo from "./kontentLogo";

const Header = () => {

  return (
  <header className="au-header grid-container">
      <div className="au-header__logo">
          <h1>
            <a href="/"><KontentLogo></KontentLogo></a>
          </h1>
      </div>

      <Navigation />
      <MobileNavigation />

      <div className="au-header__social">
          <a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
      </div>
  </header>
)}

export default Header;
