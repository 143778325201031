import React from "react"
import { graphql, useStaticQuery } from 'gatsby';

const KontentLogo = () => {
const data = useStaticQuery(graphql`
    query MyQuery {
        allImageSharp(filter: {fixed: {originalName: {eq: "Test logo firmy.png"}}}) {
            nodes {
                id
                fixed {
                    originalName
                    src
                    base64
                }
            }
        }
    }`)
    var logo = data.allImageSharp?.nodes[0]?.fixed;
    return (
        <img src={logo?.src} alt={logo?.originalName}/>
    )
  }
  
  export default KontentLogo
