import React from 'react'

const CookieBar = () => {
    return (
    <div className="cookie-bar">
        <div className="cookie-bar-container">
            <p className="cookie-bar__text"><strong>Ważne:</strong> strona wykorzystuje pliki cookies. Używamy plików cookies, aby ułatwić Ci korzystanie z naszego serwisu oraz do celów statystycznych. Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci urządzenia. Pamiętaj, że możesz samodzielnie zarządzać cookies, zmieniając ustawienia przeglądarki. Więcej informacji w naszej <span data-open="policy-modal">polityce prywatności</span>.</p>
            <button className="cookie-bar__button button" name="button">Zgoda</button>
        </div>
	</div>
    )
}

export default CookieBar
