import React from 'react'

const Navigation = () => {
    return (
    <div className="au-header__menu show-for-large">
        <ul>
            <li><a href="#test">Menuitem1</a></li>
            <li><a href="#training">Menuitem2</a></li>
            <li><a href="#about">Menuitem3</a></li>
            <li><a href="#contact">Menuitem4</a></li>
        </ul>
    </div>
    )
}

export default Navigation;